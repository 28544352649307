import React from "react"
import Layout from "../components/layout"
import HomeHero from "../components/HomeHero"
import BlogSection from "../components/BlogSection"
import { graphql } from "gatsby"

export default ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      <BlogSection posts={posts} />
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, filter: {fileAbsolutePath: {regex: "/markdown/"}}) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            date
            author
            keywords
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(pruneLength: 90)
        }
      }
    }
  }
`
